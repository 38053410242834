import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../../components/card/card-container.jsx';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb.jsx';
import Api from '../../../api/global.service';
import env from '../../../environment.js';
import { subscribe, unsubscribe } from '../../../components/events';
import EntregasXProductorFirma from './entregas-x-productor-firma.js';
import EntregasXProductorInfo from './entregas-x-productor-info.js';
import EntregasXProductorBuscador from './entregas-x-productor-buscador.js';
import './entregas-x-productor.css';
import ExampleCustomInput from '../../../components/modal/input.jsx';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { es } from 'date-fns/locale';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInputMaterialTest from '../../../nuup/componentes/Forms/FormInputMaterialTest.jsx';

import FormSelectMaterial from '../../../nuup/componentes/Forms/FormSelectMaterial.jsx';

import { useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import formatMoneyNumber, { formatNumber } from '../../../utils/formatNumber.js';
import {
  useCoopConfig,
  precioPorKgPrecargado,
  precioPorKgPorProductor,
  getIdCatCreditoPrecosecha,
} from '../../../components/content/coop-config.jsx';
import transformWord from '../../../utils/transformWord.js';
import moment from 'moment';
import EntregasXProductorBultos from './entregas-x-productor-bultos.js';

yup.addMethod(yup.number, 'campoObligatorio', function(errorMessage, valuesPesadas) {
  return this.test(`error-obligatorio`, errorMessage, function(value, context) {
    const { path, createError } = this;
    let isValid = true;
    /*
      path : pesadas[0].bultos[2].no_bultos
    */
    let pathArr = path.split('.bultos');
    let numeroPesada = Number(pathArr[0].substring(pathArr[0].indexOf('[') + 1, pathArr[0].lastIndexOf(']')));
    let id_cat_unidad_entrega = Number(pathArr[1].substring(pathArr[1].indexOf('[') + 1, pathArr[1].lastIndexOf(']')));

    if (Number(id_cat_unidad_entrega) !== 7) {
      if (typeof valuesPesadas[numeroPesada] !== 'undefined') {
        let id_cat_unidades_entregas = valuesPesadas[numeroPesada].id_cat_unidades_entregas;
        if (id_cat_unidades_entregas.includes(Number(id_cat_unidad_entrega))) {
          if (Number.isInteger(value) && Number(value) > 0) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
      }
    }
    return isValid || createError({ path, message: errorMessage });
  });
});

let curr = moment(new Date()).toDate();
const ID_MIEL = 3;

const EntregasXProductor = () => {
  const MSJ_REQUERIDO = 'Información obligatoria';
  const coopConfig = useCoopConfig();

  const { id_cuenta, uuid_cuenta, uuid_acopio } = useParams();
  const [dataForm, setDataForm] = useState({});
  const [idCatUnidadesEntregas, setIdCatUnidadesEntregas] = useState([]);
  const [idCatCertificacion, setIdCatCertificacion] = useState([]);
  const [pesoNeto, setPesoNeto] = useState();
  const [importeBruto, setImporteBruto] = useState(null);
  const [montoPagar, setMontoPagar] = useState();
  const [showForm, setShowForm] = useState(true);
  const [showFirma, setShowFirma] = useState(false);
  const [metodosPago, setMetodosPago] = useState([]);
  const [coloresMiel, setColoresMiel] = useState([]);
  const [unidadesEntregas, setUnidadesEntregas] = useState([]);
  const [pendientePagoCredito, setPendientePagoCredito] = useState(0);
  const [lotes, setLotes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [estimacion, setEstimacion] = useState();
  const [entrega, setEntrega] = useState();
  const [cuenta, setCuenta] = useState();
  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');
  const [idMetodoPago, setIdMetodoPago] = useState('');
  const [idColorMiel, setIdColorMiel] = useState('');
  const [devuelvenCostaleras, setDevuelvenCostaleras] = useState(null);
  const [valuesPesadas, setValuesPesadas] = useState([]);

  useEffect(() => {
    getEtiquetaAnticipo();
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

  const customStyles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    control: base => ({
      ...base,
    }),
    clearIndicator: base => ({
      ...base,
      position: 'absolute',
      right: 0,
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#666',
        fontSize: 14,
      };
    },
  };

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="fa fa-search" aria-hidden="true" style={{ position: 'initial' }}></i>
        </components.DropdownIndicator>
      )
    );
  };

  const handleChange = selected => {
    setValue('id_recoleccion_acopio', selected.value);
  };

  useEffect(() => {
    getMetodosPago();
    getColoresMiel();
    getUnidadesEntregas();
    getPrecioKgPrecargado();
  }, []);

  const getPrecioKgPrecargado = () => {
    if (precioPorKgPrecargado()) {
      // Obneter el precio por cooperativa
      Api.getAll('admin/acopios/precio_por_kg_precargado').then(response => {
        if (typeof response.data[0] !== 'undefined') {
          if (typeof response.data[0].precio !== 'undefined') {
            setValue('precio_por_kg', response.data[0].precio);
          }
        }
      });

      if (precioPorKgPorProductor()) {
        // Obneter el precio por productor
        Api.get('admin/acopios/precio_por_kg_por_productor', id_cuenta).then(response => {
          if (typeof response.data[0] !== 'undefined') {
            if (typeof response.data[0].precio !== 'undefined') {
              setValue('precio_por_kg', response.data[0].precio);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    subscribe('Entrega::onReturn', e => handleReturn(e));
    subscribe('Entrega::Estimacion', e => handleEstimacion(e));
    subscribe('Entrega::Entregas', e => handleEntregas(e));
    subscribe('Entrega::Pagos', e => handlePagos(e));
    subscribe('Entrega::Creditos', e => handleCreditos(e));
    subscribe('Entrega::Cuenta', e => handleCuenta(e));
    return () => {
      unsubscribe('Entrega::onReturn');
      unsubscribe('Entrega::Estimacion');
      unsubscribe('Entrega::Entregas');
      unsubscribe('Entrega::Pagos');
      unsubscribe('Entrega::Creditos');
      unsubscribe('Entrega::Cuenta');
    };
  }, []);

  const handleCuenta = event => {
    setCuenta(event.detail);
  };

  const handleEstimacion = event => {
    setEstimacion(event.detail);
  };

  const handleEntregas = event => {
    setEntrega(event.detail);
  };

  const handlePagos = () => {
    //TODO: remove?
  };

  const handleCreditos = event => {
    setValue('creditos', event.detail.raw);
    setPendientePagoCredito(event.detail.total_saldo);
  };

  const handleReturn = () => {
    setShowForm(true);
    setShowFirma(false);
  };

  const [formData] = useState({
    id_acopio: 0,
    id_cuenta: id_cuenta,
    id_ciclo: localStorage.getItem(`${env.appName}-ciclo_id`),
    uuid: '',
    fecha_ejecucion: curr,
    fecha_cosecha: null,
    pesadas: [
      {
        id_acopio_pesada: 0,
        id_cat_unidades_entregas: [],
        peso_bruto: '',
        peso_tara: '',
        peso_descuento: '',
        bultos: [],
      },
    ],
    id_acopio_pesada: 0,
    id_acopio_muestreo: 0,
    rendimiento: '',
    humedad: '',
    mancha: '',
    id_color: '',
    observaciones: '',
    lote: '',
    id_cat_certificacion: '',
    id_acopio_pago: 0,
    precio_por_kg: '',
    recuperacion_credito: '',
    id_metodo_pago: '',
    monto_pagado: '',
    id_recoleccion_acopio: '',
    creditos: [],
    firma_image: '',
    id_cat_costaleras_estatus: null,
  });

  let patternNumero2Decimales = /^\d+(\.\d{0,2})?$/;
  const validacionNumero2Decimales = yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(0, 'se espera que sea mayor o igual a 0')
    .test('is-decimal', 'Solo se permiten dos decimales', val => {
      if (val != undefined) {
        return patternNumero2Decimales.test(val);
      }
      return true;
    });

  const validacionPorcentajes = () => {
    return yup
      .number()
      .min(0, 'Se espera un valor entero entre 0 y 100')
      .max(100, 'Se espera un valor entero entre 0 y 100')
      .test('is-decimal', 'Solo se permiten dos decimales', val => {
        if (val != undefined) {
          return patternNumero2Decimales.test(val);
        }
        return true;
      })
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable();
  };

  const bultosSchema = {
    no_bultos: yup
      .number()
      .integer('Debe introducir un valor entero')
      .transform(value => (isNaN(value) ? undefined : value))
      .campoObligatorio(MSJ_REQUERIDO, valuesPesadas),
  };

  const pesadaSchema = {
    id_acopio_pesadas: yup
      .number()
      .min(0)
      .nullable(),
    id_cat_unidades_entregas: yup
      .array()
      .min(1, 'Seleccione al menos una opción')
      .required('Seleccione al menos una opción'),
    peso_bruto: validacionNumero2Decimales.min(0.01, 'se espera que sea mayor a 0').required(MSJ_REQUERIDO),
    peso_tara: validacionNumero2Decimales.min(0.01, 'se espera que sea mayor a 0').required(MSJ_REQUERIDO),
    peso_descuento: validacionNumero2Decimales.lessThan(yup.ref('peso_bruto'), 'El peso descuento debe ser menos al peso bruto'),
    bultos: yup.array().of(yup.object().shape(bultosSchema)),
  };

  const schema = yup.object().shape({
    id_acopio: yup
      .number()
      .min(0)
      .nullable(),
    id_acopio_pesada: yup
      .number()
      .min(0)
      .required(),
    id_acopio_muestreo: yup
      .number()
      .min(0)
      .required(),
    id_acopio_pago: yup
      .number()
      .min(0)
      .required(),
    id_cuenta: yup.number().min(1),
    uuid: yup.string(),
    fecha_ejecucion: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(MSJ_REQUERIDO),
    fecha_cosecha: yup.date().nullable(),
    // .transform((curr, orig) => (orig === '' ? null : curr)),
    // .required(MSJ_REQUERIDO),
    pesadas: yup.array().of(yup.object().shape(pesadaSchema)),
    rendimiento: validacionPorcentajes(),
    humedad: validacionPorcentajes(),
    mancha: validacionPorcentajes(),
    id_color: yup.string().nullable(),
    observaciones: yup.string().nullable(),
    id_cat_certificacion: yup
      .string()
      .test('len', 'Información requerida', val => val.length > 0 || !coopConfig.mostrarTipo())
      .nullable(),
    precio_por_kg: validacionNumero2Decimales
      .min(0.01, 'Se espera un valor mayor a cero')
      .transform(value => (isNaN(value) ? undefined : value))
      .required(MSJ_REQUERIDO),
    recuperacion_credito: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .when([], {
        is: () => formatNumber(pendientePagoCredito) > 0,
        then: yup
          .number()
          .min(0, 'Se espera un valor mayor o igual a 0')
          .max(formatNumber(pendientePagoCredito), 'Se espera un valor menor o igual a ' + formatMoneyNumber(pendientePagoCredito))
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable(),
        otherwise: yup
          .number()
          .min(0, 'Se espera un valor mayor o igual a 0')
          .max(formatNumber(pendientePagoCredito), 'Se espera un valor menor o igual a ' + formatMoneyNumber(pendientePagoCredito))
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable(),
      }),
    monto_pagado: validacionNumero2Decimales
      .max(formatNumber(montoPagar), 'El monto no debe ser mayor a $ ' + formatMoneyNumber(montoPagar))
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable(),
    id_metodo_pago: yup.number().when('monto_pagado', {
      is: val => val > 0,
      then: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .required(MSJ_REQUERIDO),
      otherwise: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable(),
    }),

    id_recoleccion_acopio: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable(),
    creditos: yup
      .array()
      .min(0)
      .nullable(),
    firma_image: yup.string().nullable(),
    id_cat_costaleras_estatus: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: async (data, context, options) => {
      return yupResolver(schema)(data, context, options);
    },
    mode: 'onBlur',
    defaultValues: formData,
  });

  /*
const {
  register,
  handleSubmit,
  formState: { errors },
  control,
  setValue,
  setFocus,
  watch,
  getValues
} = useForm({
  resolver: yupResolver(schema),
  mode: "onBlur",
  defaultValues: formData,
});
*/

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'pesadas',
  });

  const handlerSelectChange = (value, index) => {
    setIdCatUnidadesEntregas(value);
    setValue(`pesadas[${index}].id_cat_unidades_entregas`, value);
  };

  const handlerSelectChangeTipos = selectedOption => {
    if (selectedOption.length > 1) {
      let selectedOptionFilter = selectedOption.filter(option => [15, 16].includes(Number(option.value)));
      if (selectedOptionFilter.length === 2) {
        selectedOption = selectedOption.filter(option => option.value !== 16);
      }
    }

    setIdCatCertificacion(selectedOption);
    let unidadesArr = selectedOption.map(function(element) {
      return element.value;
    });

    setValue('id_cat_certificacion', unidadesArr.join(','));
  };

  const handlerSelectMetodoPago = selectedOption => {
    setIdMetodoPago(selectedOption);
    setValue('id_metodo_pago', selectedOption);
  };

  const handlerSelectColorMiel = selectedOption => {
    setIdColorMiel(selectedOption);
    setValue('id_color', selectedOption);
  };

  const onSubmit = values => {
    if (!moment(values.fecha_cosecha).isValid()) values.fecha_cosecha = '';
    setDataForm(values);
    setShowFirma(true);
    setShowForm(false);
  };

  useEffect(() => {
    watch(values => {
      let pesadas = values.pesadas;
      setValuesPesadas(pesadas);
      let _pesoNeto = 0;
      let _importeBruto = 0;

      try {
        for (let index = 0; index < pesadas.length; index++) {
          if (pesadas[index].peso_bruto >= 0 && pesadas[index].peso_tara >= 0) {
            _pesoNeto += pesadas[index].peso_bruto - pesadas[index].peso_tara - (pesadas[index].peso_descuento || 0);
          }
        }

        if (_pesoNeto > 0) {
          setPesoNeto(_pesoNeto);
        }

        if (values.precio_por_kg > 0) {
          _importeBruto = _pesoNeto * values.precio_por_kg;
          if (_importeBruto > 0) {
            setImporteBruto(_importeBruto);
          }
        }

        if (values.recuperacion_credito >= 0) {
          if (_importeBruto > 0) {
            setMontoPagar(_importeBruto - values.recuperacion_credito);
          }
        }
      } catch (error) {
        //console.error(error);
      }
    });
  }, [watch]);

  const getUnidadesEntregas = () => {
    Api.getAll('acopios/catalogos/unidades_entrega').then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'] };
      });
      setUnidadesEntregas(_items);
    });
  };

  const getMetodosPago = () => {
    Api.getAll('acopios/catalogos/metodos_pago').then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'] };
      });
      setMetodosPago(_items);
      if (_items.length === 1) {
        setIdMetodoPago(_items[0].value);
        setValue('id_metodo_pago', _items[0].value);
      }
    });
  };

  const getColoresMiel = () => {
    Api.getAll('acopios/catalogos/colores_miel').then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'], color: item['color'] };
      });
      setColoresMiel(_items);
    });
  };

  useEffect(() => {
    if (uuid_acopio !== undefined && unidadesEntregas.length > 0) {
      getDataEntrega();
    }
  }, [uuid_acopio, unidadesEntregas, tipos]);

  const setdatosGenerales = data => {
    setValue('id_acopio', data.id);
    setValue('uuid', data.uuid);
    setValue('fecha_ejecucion', moment(data.fecha_ejecucion).toDate());
    moment(data.fecha_cosecha).isValid() && setValue('fecha_cosecha', moment(data.fecha_cosecha).toDate());
    setDevuelvenCostaleras(data.id_cat_costaleras_estatus);
    setValue('id_cat_costaleras_estatus', data.id_cat_costaleras_estatus);
    setValue('id_recoleccion_acopio', data.id_recoleccion_acopio);
  };

  const getUnidadesEntrega = (id_acopio_pesadas, acopio_pesada_cat_unidades) => {
    let unidades = [];
    acopio_pesada_cat_unidades
      .filter(apcu => Number(apcu.id_acopio_pesadas) === Number(id_acopio_pesadas))
      .reduce(function(prev, curr) {
        return unidades.push(Number(curr.id_cat_unidades_entregas));
      }, null);
    return unidades;
  };

  const setdatosBultos = (id_acopio_pesadas, acopio_pesada_cat_unidades) => {
    let bultos_data = acopio_pesada_cat_unidades.filter(unidad => Number(unidad.id_acopio_pesadas) === Number(id_acopio_pesadas));
    return bultos_data;
  };

  const setdatosPesada = (acopio_pesadas, acopio_pesada_cat_unidades) => {
    for (let ap = 0; ap < acopio_pesadas.length; ap++) {
      let id_cat_unidades_entregas = getUnidadesEntrega(acopio_pesadas[ap].id, acopio_pesada_cat_unidades);

      let pesada = {
        id_acopio_pesadas: acopio_pesadas[ap].id,
        id_cat_unidades_entregas: id_cat_unidades_entregas,
        peso_bruto: acopio_pesadas[ap].peso_bruto / 100,
        peso_tara: acopio_pesadas[ap].peso_tara / 100,
        peso_descuento: acopio_pesadas[ap].peso_descuento > 0 ? acopio_pesadas[ap].peso_descuento / 100 : null,
        //bultos: setdatosBultos(acopio_pesadas[ap].id,acopio_pesada_cat_unidades),
        bultosData: setdatosBultos(acopio_pesadas[ap].id, acopio_pesada_cat_unidades),
      };

      setTimeout(() => {
        setValue(`pesadas[${ap}]`, pesada);
        remove(ap);
        insert(ap, pesada);
      }, 100);
    }
  };

  const setdatosTipos = acopio_tipos => {
    let tiposArr = [];
    for (let u = 0; u < acopio_tipos.length; u++) {
      tiposArr.push(Number(acopio_tipos[u].id_cat_certificacion));
    }

    let _idCatCer = tipos.filter(tipo => tiposArr.includes(Number(tipo.value)));
    setIdCatCertificacion(_idCatCer);
    setValue('id_cat_certificacion', tiposArr.join(','));
  };

  const setdatosMuestreo = acopio_muestreos => {
    if (typeof acopio_muestreos[0] !== 'undefined') {
      setValue('id_acopio_muestreo', acopio_muestreos[0].id ?? '');
      setValue('rendimiento', acopio_muestreos[0].rendimiento ?? '');
      setValue('humedad', acopio_muestreos[0].humedad ?? '');
      setValue('mancha', acopio_muestreos[0].mancha ?? '');
      setValue('observaciones', acopio_muestreos[0].observaciones ?? '');
      setValue('id_color', acopio_muestreos[0].id_color ?? '');
      setIdColorMiel(acopio_muestreos[0].id_color);
    }
  };

  const setdatosPago = (acopio_pagos, precio_por_kg) => {
    setValue('precio_por_kg', precio_por_kg / 100);
    if (typeof acopio_pagos[0] !== 'undefined') {
      setValue('id_acopio_pago', acopio_pagos[0].id);
      setValue('folio', acopio_pagos[0].folio);
      setValue('id_centro_acopio', acopio_pagos[0].id_centro_acopio);
      let id_metodo_pago = metodosPago.filter(metodo => [Number(acopio_pagos[0].id_metodo_pago)].includes(metodo.value));
      if (id_metodo_pago.length === 1) {
        setValue('id_metodo_pago', id_metodo_pago[0].value);
        setIdMetodoPago(id_metodo_pago[0].value);
      }
      let monto_pagado = 0;
      acopio_pagos.forEach(ap => {
        monto_pagado += ap.total > 0 ? ap.total / 100 : Number(ap.total);
      });
      if (monto_pagado > 0) {
        setValue('monto_pagado', monto_pagado);
      }

      setValue('firma_image', acopio_pagos[0].firma_image);
    }
  };

  const getDataEntrega = () => {
    Api.get('admin/acopios/entregas', uuid_acopio).then(response => {
      if (response.status === 200) {
        let dataEntrega = response.data;
        if (typeof dataEntrega.id !== 'undefined') {
          setdatosGenerales(dataEntrega);
          setdatosPesada(dataEntrega.acopio_pesadas, dataEntrega.acopio_pesada_cat_unidades);
          setdatosMuestreo(dataEntrega.acopio_muestreos);
          setdatosPago(dataEntrega.acopio_pagos, dataEntrega.precio_por_kg);
          setdatosTipos(dataEntrega.acopio_tipos);
        }
      }
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  useEffect(() => {
    getTipos();
    getLotes();
  }, []);

  const getLotes = () => {
    Api.get('admin/acopios/entregas/lotes', localStorage.getItem(`${env.appName}-ciclo_id`)).then(response => {
      let _lotes = [];
      _lotes.push({ value: '', label: 'Sin Asignar', folio: null, volumen: null });
      response.data.map(function(item) {
        _lotes.push({
          value: item['id'],
          label: item['nombre'] + (item['folio'] ? ' | ' + item['folio'] : ''),
          folio: item['folio'],
          volumen: item['volumen'],
        });
      });
      setLotes(_lotes);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const getTipos = () => {
    Api.getAll('acopios/catalogos/certificaciones').then(response => {
      let _items = response.data.map(function(item) {
        return {
          value: item['id'],
          label: item['nombre'],
        };
      });
      setTipos(_items);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const agregaPesada = () => {
    append({
      id_acopio_pesadas: 0,
      id_cat_unidades_entregas: [],
      peso_bruto: '',
      peso_tara: '',
      peso_descuento: '',
      no_bultos: '',
      no_costales: '',
      no_tambores: '',
      no_sacos: '',
      no_costalillos: '',
      no_botes: '',
      bolsa_plastico: '',
      costal_yute: '',
      costal_pergaminero: '',
    });
  };

  const eliminaPesada = index => {
    remove(index);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Entregas</li>
        </ol>
        <EntregasXProductorBuscador uuid_cuenta={uuid_cuenta} />
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-xl-8 col-lg-7">
            {showForm && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-xl-4 col-lg-6" style={{ zIndex: 100000 }}>
                    <p className="titulo-input">{'Registro de nueva entrega'}</p>

                    <Controller
                      control={control}
                      name="fecha_ejecucion"
                      render={({ field }) => (
                        <DatePicker
                          customInput={<ExampleCustomInput />}
                          dateFormat={'dd/MMM/yyyy'}
                          onChange={date => field.onChange(date)}
                          selected={field.value}
                          ariaLabelledBy="cosa"
                          wrapperClassName="w-100"
                          locale={es}
                        />
                      )}
                    />
                    {errors?.fecha_ejecucion && <div className="invalid-feedback">{errors?.fecha_ejecucion.message}</div>}
                  </div>
                  {+coopConfig?.config?.cooperativa?.producto?.id === ID_MIEL && (
                    <div className="col-xl-4 col-lg-6" style={{ zIndex: 100000 }}>
                      <p className="titulo-input">{'Fecha de cosecha'}</p>

                      <Controller
                        control={control}
                        name="fecha_cosecha"
                        render={({ field }) => (
                          <DatePicker
                            customInput={<ExampleCustomInput />}
                            dateFormat={'dd/MMM/yyyy'}
                            onChange={date => field.onChange(date)}
                            selected={field.value}
                            wrapperClassName="w-100"
                            locale={es}
                          />
                        )}
                      />
                      {errors?.fecha_cosecha && <div className="invalid-feedback">{errors?.fecha_cosecha.message}</div>}
                    </div>
                  )}
                </div>

                <div className="row pt-3 pl-2">
                  <p className="titulo-input">{'Datos de pesada'}</p>
                </div>

                <div className="row pl-2 mt-2">
                  {estimacion && entrega && estimacion?.est_peso_estimado_total > 0 && (
                    <p className="info-input">
                      {'A este productor/a le faltan ' +
                        formatMoneyNumber((estimacion?.est_peso_estimado_total || 0) - (entrega?.entregas_peso_neto_total || 0)) +
                        ` ${coopConfig.acopioUnits()?.abreviacion_plural} para completar su estimación de cosecha`}
                    </p>
                  )}
                </div>

                {fields.map((pesada, index) => (
                  <>
                    {fields.length > 1 && <h4 className="mt-1 mb-3">{'Pesada: ' + (index + 1)}</h4>}
                    <div className="row">
                      <div className="col-xl-4 col-lg-12">
                        <FormSelectMaterial
                          placeholder="Recipientes de entrega"
                          label="Recipientes de entrega"
                          options={unidadesEntregas}
                          name={`pesadas[${index}].id_cat_unidades_entregas`}
                          multiple={true}
                          error={errors.pesadas?.[index]?.id_cat_unidades_entregas}
                          register={register}
                          onChange={data => handlerSelectChange(data, index)}
                          value={getValues().pesadas[index].id_cat_unidades_entregas}
                        />

                        {errors.pesadas?.[index]?.id_cat_unidades_entregas && (
                          <div className="invalid-feedback">{errors.pesadas?.[index]?.id_cat_unidades_entregas.message}</div>
                        )}
                      </div>

                      <EntregasXProductorBultos
                        errors={errors}
                        unidadesEntregas={unidadesEntregas}
                        id_cat_unidades_entregas={getValues().pesadas[index]?.id_cat_unidades_entregas}
                        nestIndex={index}
                        values={getValues()}
                        setValue={setValue}
                        {...{ control, register }}
                      />
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-3">
                        <FormInputMaterialTest
                          id={`pesadas[${index}].peso_bruto`}
                          register={register}
                          control={control}
                          error={errors.pesadas?.[index]?.peso_bruto}
                          placeholder={`Peso bruto (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                          label=""
                          step=".01"
                          value={getValues().pesadas[index]?.peso_bruto}
                        />
                      </div>
                      <div className="col-md-3">
                        <FormInputMaterialTest
                          id={`pesadas[${index}].peso_tara`}
                          register={register}
                          control={control}
                          error={errors.pesadas?.[index]?.peso_tara}
                          placeholder={`Peso tara (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                          label=""
                          step=".01"
                          value={getValues().pesadas[index]?.peso_tara}
                        />
                      </div>
                      {coopConfig.mostrarDescuento() && (
                        <div className="col-md-3">
                          <FormInputMaterialTest
                            id={`pesadas[${index}].peso_descuento`}
                            register={register}
                            control={control}
                            error={errors.pesadas?.[index]?.peso_descuento}
                            placeholder={`Peso descuento (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                            label=""
                            step=".01"
                            value={getValues().pesadas[index]?.peso_descuento}
                          />
                        </div>
                      )}
                      {index > 0 && (
                        <div className="col-md-3">
                          <button
                            className="btn btn-outline-danger mx-1"
                            type="button"
                            onClick={() => {
                              eliminaPesada(index);
                            }}>
                            Eliminar
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ))}
                <div className="row">
                  <button className="btn btn-outline-dark col-md-3 mt-3" type="button" onClick={agregaPesada}>
                    Agrega otra pesada
                  </button>
                </div>

                {pesoNeto && (
                  <div className="row pl-2">
                    <p className={formatMoneyNumber(pesoNeto) >= 0 ? 'info-input' : 'in-valid'}>
                      Peso neto ={' '}
                      <span style={{ fontSize: 24 }}>
                        {formatMoneyNumber(pesoNeto) + ' ' + coopConfig.acopioUnits()?.abreviacion_plural}
                      </span>
                    </p>
                  </div>
                )}

                {coopConfig.mostrarMuestreo && (
                  <>
                    <div className="row pt-3 pl-2">
                      <p className="titulo-input">{'Muestreo'}</p>
                    </div>

                    <div className="row">
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_rendimiento && (
                        <div className="col-md-4">
                          <FormInputMaterialTest
                            id="rendimiento"
                            name="rendimiento"
                            label=""
                            placeholder="Rendimiento (%)"
                            error={errors.rendimiento}
                            register={register}
                            step=".01"
                            value={getValues().rendimiento}
                          />
                        </div>
                      )}

                      {!!+coopConfig?.config?.config_muestreo?.mostrar_humedad && (
                        <div className="col-md-4">
                          <FormInputMaterialTest
                            id="humedad"
                            name="humedad"
                            label=""
                            placeholder="Humedad (%)"
                            error={errors.humedad}
                            register={register}
                            step=".01"
                            value={getValues().humedad}
                          />
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_mancha && (
                        <div className="col-md-4">
                          <FormInputMaterialTest
                            id="mancha"
                            name="mancha"
                            label=""
                            placeholder="Mancha (%)"
                            error={errors.mancha}
                            register={register}
                            step=".01"
                            value={getValues().mancha}
                          />
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_color && (
                        <div className="col-md-4">
                          <FormSelectMaterial
                            placeholder="Color"
                            label="Color"
                            options={coloresMiel}
                            id={'id_color'}
                            error={errors.id_color}
                            register={register}
                            onChange={handlerSelectColorMiel}
                            value={idColorMiel}
                          />
                          {errors?.id_color && <div className="invalid-feedback">{errors?.id_color.message}</div>}
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_observaciones && (
                        <div className="col-md-4">
                          <FormInputMaterialTest
                            id="observaciones"
                            name="observaciones"
                            label=""
                            placeholder="Observaciones"
                            error={errors.observaciones}
                            register={register}
                            step=".01"
                            value={getValues().observaciones}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}

                {(coopConfig.mostrarTipo() || coopConfig.mostrarLote()) && (
                  <>
                    <div className="row pt-3 pl-2">
                      <p className="titulo-input">
                        {(coopConfig.mostrarTipo() ? 'Tipo' : '') +
                          (coopConfig.mostrarLote() && coopConfig.mostrarTipo() ? ' y ' : '') +
                          (coopConfig.mostrarLote() ? 'Lote' : '')}
                        {coopConfig.mostrarLote() && (
                          <span style={{ fontSize: 10, paddingLeft: 5 }}>{'¿Quieres asignar a esta entrega un lote?'}</span>
                        )}
                      </p>
                    </div>

                    <div className="row">
                      {coopConfig.mostrarTipo() && (
                        <div className="col-xl-4 col-lg-12">
                          <Controller
                            control={control}
                            name="id_cat_certificacion"
                            render={() => (
                              <Select
                                isLoading={tipos.length === 0}
                                isSearchable={false}
                                isMulti={true}
                                placeholder="Tipo de producto"
                                options={tipos}
                                onChange={handlerSelectChangeTipos}
                                value={idCatCertificacion}
                                autosize={false}
                                clearable={false}
                                styles={customStyles}
                                defaultOptions
                              />
                            )}
                          />
                          {errors?.id_cat_certificacion && <div className="invalid-feedback">{errors?.id_cat_certificacion.message}</div>}
                        </div>
                      )}

                      {coopConfig.mostrarLote() && (
                        <div className="col-xl-4 col-lg-12">
                          <Controller
                            control={control}
                            name="id_recoleccion_acopio"
                            render={() => (
                              <Select
                                isLoading={lotes.length === 0}
                                components={{ DropdownIndicator }}
                                classNamePrefix="_select_"
                                isSearchable={true}
                                isClearable={true}
                                autosize={false}
                                placeholder="Nº o código de lote"
                                options={lotes}
                                noOptionsMessage={() => 'No hay elementos por mostrar'}
                                onChange={handleChange}
                                value={lotes.filter(unidad => unidad.value === getValues().id_recoleccion_acopio)}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}

                {coopConfig.mostrarCostalera() && (
                  <>
                    <div className="row mt-1">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <p className="titulo-pregunta">{'¿Se devuelven costaleras a la cooperativa?'}</p>
                      </div>
                    </div>

                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <input
                          type="radio"
                          name="id_cat_costaleras_estatus"
                          value={1}
                          checked={Number(devuelvenCostaleras) === 1}
                          onChange={() => {
                            setValue('id_cat_costaleras_estatus', 1);
                            setDevuelvenCostaleras(1);
                          }}
                        />{' '}
                        Si
                      </div>
                      <div className="col-auto">
                        <input
                          type="radio"
                          name="id_cat_costaleras_estatus"
                          value={0}
                          checked={Number(devuelvenCostaleras) === 2}
                          onChange={() => {
                            setValue('id_cat_costaleras_estatus', 2);
                            setDevuelvenCostaleras(2);
                          }}
                        />{' '}
                        No
                      </div>
                      <div className="col-auto">
                        <input
                          type="radio"
                          name="id_cat_costaleras_estatus"
                          value={0}
                          checked={devuelvenCostaleras === null}
                          onChange={() => {
                            setValue('id_cat_costaleras_estatus', null);
                            setDevuelvenCostaleras(null);
                          }}
                        />{' '}
                        No aplica
                      </div>

                      {errors?.id_cat_costaleras_estatus && (
                        <div className="invalid-feedback">{errors?.id_cat_costaleras_estatus.message}</div>
                      )}
                    </div>
                  </>
                )}

                <div className="row pt-3 pl-2">
                  <p className="titulo-input">{'Pago'}</p>
                </div>

                <div className="row mb-2">
                  <div className="col-xl-4 col-lg-6">
                    <FormInputMaterialTest
                      id="precio_por_kg"
                      name="precio_por_kg"
                      label=""
                      placeholder={`Precio / ${coopConfig.acopioUnits()?.abreviacion} (${coopConfig.currency()?.symbol})`}
                      error={errors.precio_por_kg}
                      register={register}
                      step=".01"
                      value={getValues().precio_por_kg}
                    />
                  </div>
                  {importeBruto && (
                    <div className="col-xl-4 col-lg-6">
                      <p className="importe-total">{`Importe bruto ${coopConfig.currency()?.symbol} ` + formatMoneyNumber(importeBruto)}</p>
                    </div>
                  )}
                </div>

                {getIdCatCreditoPrecosecha() > 1 && (
                  <>
                    <div className="row pl-2">
                      <p className="info-input">
                        {`Este productor tiene ${coopConfig.currency()?.symbol} ` +
                          formatMoneyNumber(pendientePagoCredito) +
                          ' pendientes de pago de ' +
                          transformWord(etiquetaAnticipos, ['plural'])}
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <FormInputMaterialTest
                          id="recuperacion_credito"
                          name="recuperacion_credito"
                          label=""
                          placeholder={
                            'Recuperación ' + transformWord(etiquetaAnticipos, ['plural']) + ` (${coopConfig.currency()?.symbol})`
                          }
                          error={errors.recuperacion_credito}
                          register={register}
                          step=".01"
                          value={getValues().recuperacion_credito}
                        />
                      </div>
                      {montoPagar && (
                        <div className="col-md-6">
                          <p className="importe-total">
                            {`Monto a pagar ${coopConfig.currency()?.symbol} ` + formatMoneyNumber(montoPagar)}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="row" style={{ marginTop: 10 }}>
                  <div className="input-group" style={{ borderStyle: 'none', marginLeft: -5 }}>
                    <div style={{ paddingTop: 8 }} className="col-xl-4 col-lg-6">
                      <FormSelectMaterial
                        placeholder="Método de pago"
                        label="Método de pago"
                        options={metodosPago}
                        id={'id_metodo_pago'}
                        error={errors.id_metodo_pago}
                        register={register}
                        onChange={handlerSelectMetodoPago}
                        value={idMetodoPago}
                        disabled={metodosPago.length === 1}
                      />
                      {errors?.id_metodo_pago && <div className="invalid-feedback">{errors?.id_metodo_pago.message}</div>}
                    </div>
                    <div className="col-xl-4 col-lg-6">
                      <FormInputMaterialTest
                        id="monto_pagado"
                        name="monto_pagado"
                        label=""
                        placeholder={`Monto pagado (${coopConfig.currency()?.symbol})`}
                        error={errors.monto_pagado}
                        register={register}
                        step=".01"
                        value={getValues().monto_pagado}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <button className="btn btn-sirio mt-3" type="submit">
                      Continuar
                    </button>
                  </div>
                </div>
              </form>
            )}
            {showFirma && (
              <EntregasXProductorFirma
                metodosPago={metodosPago}
                data={dataForm}
                cuenta={cuenta}
                importeBruto={importeBruto}
                pesoNeto={pesoNeto}
              />
            )}
          </div>
          <div className="col-xl-4 col-lg-5">
            <EntregasXProductorInfo uuid_cuenta={uuid_cuenta} id_cuenta={id_cuenta} />
          </div>
        </div>
        {/*
        <button onClick={() => {
          setShowForm(!showForm);
          setShowFirma(!showFirma);
        }} className="btn btn-sirio mt-3">Continuar</button>
      */}
      </CardContainer>
    </div>
  );
};

export default EntregasXProductor;
