import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import transformWord from '../../utils/transformWord.js';
import useEtiquetas from '../../hooks/useEtiquetas.jsx';
import xlsx from 'json-as-xlsx';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';

const FILTER = {
  ALL: 0,
  GEO_ONLY: 1,
  NON_GEO: 2,
};

const ParcelasList = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [geoFilter, setGeoFilter] = useState(0);
  const [blocking, setBlocking] = useState(true);
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { etiquetas } = useEtiquetas();

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: false,
        width: 65,
      },
      {
        Header: 'PRODUCTOR',
        accessor: 'cuenta',
        sortable: true,
        Cell: ({ row, value }) => (
          <Link to={{ pathname: `/admin/cuentas/perfil/${row.original.cuenta_uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
            <span>{value}</span>
          </Link>
        ),
      },
      {
        Header: 'NOMBRE ' + transformWord(etiquetas?.parcela, ['uppercase']),
        accessor: 'nombre',
        sortable: true,
        Cell: ({ row, value }) => (
          <Link to={{ pathname: `/admin/parcelas/perfil/print/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
            <span>{value}</span>
          </Link>
        ),
      },
      {
        Header: 'GEOLOCALIZADA',
        accessor: 'poligono',
        sortable: true,
        Cell: ({ value }) => {
          if (value !== null) {
            return <span>Sí</span>;
          }
          return <span>pendiente</span>;
        },
      },
      {
        Header: 'MUNICIPIO',
        accessor: 'municipio',
        sortable: true,
      },
      {
        Header: 'COMUNIDAD',
        accessor: 'localidad',
        sortable: true,
      },
      {
        Header: 'SUPERFICIE (HA)',
        accessor: 'superficie',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value} digits={2}></LabelNumber>;
        },
      },
      {
        Header: transformWord(etiquetas?.planta, ['plural', 'uppercase']) + ' TOTALES',
        accessor: 'plantas',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      },
    ],
    [etiquetas]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'cuenta', label: 'PRODUCTOR' },
      { value: 2, target: 'nombre', label: 'NOMBRE PARCELA' },
      { value: 3, target: 'municipio', label: 'MUNICIPIO' },
      { value: 4, target: 'localidad', label: 'LOCALIDAD' },
      { value: 5, target: 'superficie', label: 'SUPERFICIE (HA)' },
      { value: 6, target: 'variedades', label: 'VARIEDADES' },
      { value: 7, target: 'plantas', label: 'PLANTAS TOTALES' },
      // { value: 6, target: 'ciclo', label: 'CICLO' },
    ],
    []
  );

  useEffect(() => {
    const getData = async () => {
      setBlocking(true);
      Api.search('parcelas_all', {
        sort: [{ column: 'cuenta', sort: 'asc' }],
      })
        .then(response => {
          setOriginalData(response.data);
          const geoParam = searchParams.get('geoFilter');
          filterGeo(geoParam, response.data);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error: ', e);
          setBlocking(false);
        });
    };
    getData();
  }, []);

  const filterGeo = (filter, recievedData = null) => {
    const data = recievedData ? recievedData : originalData;
    if (filter == FILTER.GEO_ONLY) {
      setGeoData(data.filter(item => item.poligono !== null));
      setData(data.filter(item => item.poligono !== null));
      setGeoFilter(FILTER.GEO_ONLY);
      searchParams.set('geoFilter', FILTER.GEO_ONLY);
      history.replace({
        search: searchParams.toString(),
      });
    } else if (filter == FILTER.NON_GEO) {
      setGeoData(data.filter(item => !item.poligono));
      setData(data.filter(item => !item.poligono));
      setGeoFilter(FILTER.NON_GEO);
      searchParams.set('geoFilter', FILTER.NON_GEO);
      history.replace({
        search: searchParams.toString(),
      });
    } else {
      setGeoData(data);
      setData(data);
      setGeoFilter(FILTER.ALL);
      history.replace({
        search: '',
      });
    }
  };

  const exportarXlsx = event => {
    event.preventDefault();
    const xlsData1 = [
      {
        sheet: 'Parcelas',
        columns: [
          { label: 'ID', value: 'id' },
          { label: 'PRODUCTOR', value: 'cuenta' },
          { label: 'NOMBRE ' + transformWord(etiquetas?.parcela, ['uppercase']), value: 'nombre' },
          { label: 'GEOLOCALIZADA', value: row => (row.poligono !== null ? 'Sí' : 'No') },
          { label: 'MUNICIPIO', value: 'municipio' },
          { label: 'COMUNIDAD', value: 'localidad' },
          { label: 'SUPERFICIE (HA)', value: 'superficie' },
          { label: transformWord(etiquetas?.planta, ['plural', 'uppercase']) + ' TOTALES', value: 'plantas' },
        ],
        content: data,
      },
    ];

    const settings = {
      fileName: `${transformWord(etiquetas?.parcela, ['uppercase'])}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  return (
    <div>
      <div className="row">
        <div className="col-6">
          <Breadcrumb>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item">
                <Link to="/admin/parcelas">{transformWord(etiquetas?.parcela, ['capitalize', 'plural'])}</Link>
              </li>
              <li className="breadcrumb-item active">Listado {transformWord(etiquetas?.parcela, ['plural'])}</li>
            </ol>
          </Breadcrumb>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end">
            <Link to="/dashboard" className="btn m-r-2">
              <i style={{ color: '#789D16' }} className="fa fa-arrow-left"></i> <span style={{ color: '#789D16' }}>Volver al inicio</span>
            </Link>
          </div>
        </div>
      </div>
      <CardContainer height={'100%'}>
        <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <div className="row">
            <div className="col-6">
              <SearchColumn data={geoData} columns={searchColumns} setDataFilter={evt => setData(evt)} clearSearchItem={geoFilter} />
            </div>
            <div className="col-3">
              <FormSelectMaterial
                id="geolocalizado"
                error={false}
                label="Geo"
                value={geoFilter}
                onChange={filterGeo}
                height={36}
                options={[
                  { value: 1, label: 'Sólo geolocalizadas' },
                  { value: 2, label: 'Sólo pendientes de geolocalizar' },
                ]}
                showAll={true}
                showAllLabel="Todas"
              />
            </div>
            <div className="col-3">
              <div className="d-flex justify-content-end">
                <button onClick={e => exportarXlsx(e)} className={`btn m-r-2`} type="button">
                  <img src={env.images.icon.xls} className="pr-1" />
                  <span style={{ color: '#789D16' }}>Descargar Excel</span>
                </button>
              </div>
            </div>
          </div>
        </BlockUi>

        <NoPagination blocking={blocking} columns={columns} data={data}></NoPagination>
      </CardContainer>
    </div>
  );
};

export default ParcelasList;
